import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiCallServiceService {

  constructor(
    private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      // 'Access-Control-Allow-Headers': 'Content-Type',
      // 'Access-Control-Allow-Methods': 'POST',
      // 'Access-Control-Allow-Origin': '*',
      apikey: environment.token
    })
  }



  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }


  //save user query
  saveQueryDetial(item: any) {
    console.log("Request Validation Request----" + JSON.stringify(item));
    return this.http
      .post(environment.base_url + "query/save", item, { headers: { 'apikey': 'thebossisalwaysboss' } })
      .pipe(
        catchError(ApiCallServiceService.handleError)
      )
  }

  //login api 
  login(item: any) {
    console.log("Request login----" + JSON.stringify(item));
    return this.http
      .post(environment.base_url + "user/login", item, { headers: { 'apikey': 'thebossisalwaysboss' } })
      .pipe(
        catchError(ApiCallServiceService.handleError)
      )
  }


  //product Create api 
  productCreate(item: any) {
    console.log("Request product Create----" + JSON.stringify(item));
    return this.http
      .post(environment.base_url + "product/create", item, { headers: { 'apikey': 'thebossisalwaysboss' } })
      .pipe(
        catchError(ApiCallServiceService.handleError)
      )
  }


  //get All product api 
  getAllProduct() {
    let item2: {
      id: ""
    }
    return this.http
      .post(environment.base_url + "product/getall", item2, { headers: { 'apikey': 'thebossisalwaysboss' } })
      .pipe(
        catchError(ApiCallServiceService.handleError)
      )
  }

  // MyGetFormApi(item: any) {
  //   console.log("Request Validation Request----" + JSON.stringify(item));
  //   return this.http
  //     // .get("http://techsoleinfotech.com/tsi_api/apis/tester")
  //     .get("http://techsole.in/tsi_api/apis/tester")
  //     .pipe(
  //       catchError(ApiCallServiceService.handleError)
  //     )
  // }

  getProductById(id:any) {
    // console.log("Request Validation Request----" + JSON.stringify(item));
    return this.http
      .get(environment.base_url + "product/byid?id="+id)
      .pipe(
        catchError(ApiCallServiceService.handleError)
      )
  }
}
