import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})

export class ProjectsComponent implements OnInit {
  public isProjectsStr:string='';
  public isProject:boolean=false;
  
  constructor() { }

  ngOnInit() {
    this.isProjectsStr=localStorage.getItem("isProject");
    if(this.isProjectsStr=='true'){
      this.isProject=true;
    }
  }

}
