import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ShowmessageService {

  constructor(
    private toastController : ToastController,
    private loadingCtrl: LoadingController,
    private atrCtrl: AlertController
    ) { }

  async responseText(msgText,msgColor) {
    const toast = await this.toastController.create({
      message: msgText,
      duration: 2000,
      position: 'top',
      color : msgColor
    });
    toast.present();
  }

  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Loading',
      spinner: 'circles'
    });
    loading.present();
  }


  async loaderDismiss() {
    const loader = await this.loadingCtrl.dismiss();
  }


  async saveResponse(msg:any) {

    const alert = await this.atrCtrl.create({
      header: msg,
      // subHeader: msg,

      buttons: [
        // {
        //   text: 'Cancel',
        //   role: 'cancel',
        //   handler: () => {

        //   }
        // },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {           
          }
        }
      ]
    });
    await alert.present();
  }

}
  


