import { Component, OnInit } from '@angular/core';
import { ApiCallServiceService } from '../service/api-call-service.service';

@Component({
  selector: 'app-productdtls',
  templateUrl: './productdtls.component.html',
  styleUrls: ['./productdtls.component.scss'],
})
export class ProductdtlsComponent implements OnInit {
  public pid: any = "";
  public imageList:any=[];
  public product:any="";
  public productFirst:any="";
  public productApiResponse:any="";
  public activeFlag:boolean=true;
  public showImage:boolean=false;
  constructor( private apiService: ApiCallServiceService) {
    this.pid = localStorage.getItem('pid');
    console.log("pid----" + this.pid);
    this.getProductById();
  }

  ngOnInit() { }

  getProductById() {
    // this.showMsg.showLoading();
    this.apiService.getProductById(this.pid).subscribe((response: any) => {
      var rst = response.status
      // alert(JSON.stringify(response));
      // console.log("All get Product response:" + JSON.stringify(response));
      if (rst == "Success") {      
        this.imageList=response.images;
        this.product=response.product;
        this.productFirst=this.imageList[0];
        console.log("imageList",JSON.stringify(this.imageList));
        console.log("product",JSON.stringify(this.product));   
        if( this.imageList.length>0){
            this.showImage=true;
        }else{
          this.showImage=false;
        }
        
      } else {
      
      }
    })
  }
 
}
