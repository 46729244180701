import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { ApiTutorialComponent } from './tutorial/api-tutorial/api-tutorial.component';
import { ApplicationproductComponent } from './applicationproduct/applicationproduct.component';
import { ClientsComponent } from './clients/clients.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { LoginComponent } from './login/login.component';
import { ProductsComponent } from './products/products.component';
import { ProjectsComponent } from './projects/projects.component';
// import { TeamsComponent } from './teams/teams.component';
// import { TutorialComponent } from './tutorial/tutorial.component';
// import { StudytutorialComponent } from './studytutorial/studytutorial.component';
import { ServicesComponent } from './services/services.component';
import { ProductdtlsComponent } from './productdtls/productdtls.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AchievementsComponent } from './achievements/achievements.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  // },
  {
    path: 'products',
    component: ProductsComponent,
  },
  {
    path: 'projects',
    component: ProjectsComponent,
  },
  {
    path: 'clients',
    component: ClientsComponent,
  },
  // {
  //   path: 'teams',
  //   component: TeamsComponent,
  // },
  {
    path: 'services',
    component: ApplicationproductComponent,
  },

  {
    path: 'all_services',
    component: ServicesComponent,
  },

  // {
  //   path: 'studytutorial',
  //   component: StudytutorialComponent,
  // },
  
  // {
  //   path: 'tutorial',
  //   component: TutorialComponent,
  // },
  {
    path: 'aboutus',
    component: AboutusComponent,
  },
  {
    path: 'contactus',
    component: ContactusComponent,
  },
  {
    path: 'achievements',
    component: AchievementsComponent,
  },
  {
    path: 'productdtls',
    component: ProductdtlsComponent,
  },
  // {
  //   path: 'tutoriall',
  //   loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialModule)
  // },
  // {
  //   path: 'java-api-introduction1',
  //   loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialModule)
  // },
  // {
  //   path:'installation-sts-bundle1',
  //   loadChildren:()=>import('./tutorial/tutorial.module').then(m=>m.TutorialModule)
  // },{
  //   path:'project-setup-in-sts1',
  //   loadChildren:()=>import('./tutorial/tutorial.module').then(m=>m.TutorialModule)
  // }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
