import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  public isClientStr:string='';
  public isClient:boolean=false;

  constructor() { }

  ngOnInit() {
    this.isClientStr=localStorage.getItem("isClient");
    if(this.isClientStr=="true"){
      this.isClient=true;
    }
  }

}
