import { Component, OnInit } from '@angular/core';
import { ApiCallServiceService } from '../service/api-call-service.service';
import { ShowmessageService } from '../service/showmessage.service';
ApiCallServiceService 
@Component({
  selector: 'app-applicationproduct',
  templateUrl: './applicationproduct.component.html',
  styleUrls: ['./applicationproduct.component.scss'],
})
export class ApplicationproductComponent implements OnInit {
 public productList:any=[];
 public aboutWeb:any="";
 public titleWeb:any="";
 public aboutMobile:any="";
 public titleMobile:any="";
 public aboutSoftware:any="";
 public titleSoftware:any=""
 public aboutAIML:any="";
 public titleAIML:any=""

 public isWeb:boolean=false;
 public isMobile:boolean=false;
 public isSoftware:boolean=false;
 public isAIML:boolean=false;
 public isDigitalMkT:boolean=false;
  constructor(
    private showMsg:ShowmessageService,
    private apiService:ApiCallServiceService,

  ) { }

  ngOnInit() {
    console.log("typeService--"+localStorage.getItem("typeService") );
    
    if(localStorage.getItem("typeService") == "web"){
      this.isWeb=true
      this.isMobile=false;
      this.isSoftware=false;
      this.isAIML=false;
      this.isDigitalMkT=false;
    }else if(localStorage.getItem("typeService") == "mobile"){
      this.isWeb=false
      this.isMobile=true;
      this.isSoftware=false;
      this.isAIML=false;
      this.isDigitalMkT=false;
    }else if(localStorage.getItem("typeService") == "software"){
      this.isWeb=false
      this.isMobile=false;
      this.isSoftware=true;
      this.isAIML=false;
      this.isDigitalMkT=false;
    }else if(localStorage.getItem("typeService") == "digitalMkt"){
      this.isWeb=false;
      this.isMobile=false;
      this.isSoftware=false;
      this.isAIML=false;
      this.isDigitalMkT=true;
    }
    else if(localStorage.getItem("typeService") == "aiml"){
      this.isWeb=false;
      this.isMobile=false;
      this.isSoftware=false;
      this.isAIML=true;
      this.isDigitalMkT=false;
    }
  
    // this.getAllProductList();
  }

  // getAllProductList() {
  //   // this.showMsg.showLoading();
  //   this.apiService.getAllProduct().subscribe((response: any) => {
  //     var rst = response.status
  //     // alert(JSON.stringify(response));
  //     console.log("response:" + JSON.stringify(response));
  //     if (rst == "Success") {
  //       // this.showMsg.loaderDismiss();   
  //       this.productList=response["products"];
  //       console.log("productList---"+JSON.stringify(this.productList));          
  //     } else {
  //       // this.showMsg.loaderDismiss()
  //     }
  //   })
  // }
}
