import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallServiceService } from '../service/api-call-service.service';
import { ShowmessageService } from '../service/showmessage.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  public isProductsStr: string = 'false';
  public isProducts: boolean = false;
  public allProductList: any = [];


  constructor(
    private apiService: ApiCallServiceService,
    private showMsg: ShowmessageService,
    private router: Router

  ) { }

  ngOnInit() {
    this.isProductsStr = localStorage.getItem("isProducts");
    console.log(this.isProductsStr);
    
    if (this.isProductsStr == "true") {
      this.isProducts = true;
    } else {
      this.isProducts = false;
    }

    this.getAllProducts();
  }

  getAllProducts() {    
    this.showMsg.showLoading();
    this.apiService.getAllProduct().subscribe((response: any) => {
      var rst = response.status
      // alert(JSON.stringify(response));
      console.log("All get Product response:" + JSON.stringify(response));
      if (rst == "Success") {     
        this.allProductList=response.products;        
        this.showMsg.loaderDismiss();
      } else {
        this.showMsg.loaderDismiss()   
      }
    })
  }

  clickProductdtls(name: any,id:any) {
    console.log("Product Name---" + name);
    localStorage.setItem('pid',id);
    this.router.navigate(['/productdtls'],{ queryParams: { "appname": name }});
  }

}
